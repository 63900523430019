import { gql, useQuery } from "@apollo/client";

export default function useGetSelectedGroupCompanyBranch() {
  const { data } = useQuery<{
    selectedGroup?: number;
    selectedCompany?: number;
    selectedBranch?: number;
    selectedRole?: string;
    ClientApproval?: boolean;
  }>(
    gql`
      query getSelectedGroupCompanyBranch {
        selectedGroup @client
        selectedCompany @client
        selectedBranch @client
        selectedRole @client
        ClientApproval @client
      }
    `,
    {
      fetchPolicy: "cache-only",
    }
  );
  
  return {
    ...data,
  };
}
