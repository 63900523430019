import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import useAuth from "../hooks/useAuth";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

export default function ProtectedRoute({ ...routeProps }: ProtectedRouteProps) {
  const { loading, isLoggedIn } = useAuth()

  if(loading) {
    return <h1>Loading...</h1>
  }
  else if (isLoggedIn) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={{ pathname: "/login" }} />;
  }
}
