import { gql, useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { loggedInUserDetailsVar } from "../apollo/cache";
import { User } from "../generated/graphql-generated";
import useGetSelectedGroupCompanyBranch from "../utils/hooks/useGetSelectedGroupCompanyBranch";

export default function useAuth() {
  const history = useHistory();
  const { selectedRole } = useGetSelectedGroupCompanyBranch();
  const { loading, data } = useQuery(
    gql`
      query getLoggedInUserDetails {
        loggedInUserDetails @client
      }
    `,
    {
      fetchPolicy: "cache-only",
    }
  );

  const userData = useMemo(() => {
    return data.loggedInUserDetails ? { ...data.loggedInUserDetails, Role: selectedRole } : null;
  }, [data, selectedRole]);

  const setToken = (token: string) => {
    localStorage.setItem("token", token);
    const userInfo = localStorage.getItem("token");

    loggedInUserDetailsVar(jwtDecode<{ user: User }>(userInfo!).user);
  };

  const logOut = () => {
    localStorage.clear();
    loggedInUserDetailsVar(null);
    history.push("/login");
    window.location.reload();
  };

  const isLoggedIn = useMemo(() => userData != null, [userData]);

  return {
    setToken,
    loading,
    userData,
    isLoggedIn,
    logOut,
  };
}
