import { InMemoryCache, makeVar } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { User } from "../generated/graphql-generated";

const userInfo = localStorage.getItem("token");
export const loggedInUserDetailsVar = makeVar<User | null>(
  userInfo ? jwtDecode<any>(userInfo!).user : null
);
export const selectedGroupVar = makeVar<number | null>(null);
export const selectedCompanyVar = makeVar<number | null>(null);
export const selectedBranchVar = makeVar<number | null>(null);
export const selectedRoleVar = makeVar<string | null>(null);
export const ClientApproval = makeVar<boolean | null>(null);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        loggedInUserDetails: {
          read() {
            return loggedInUserDetailsVar();
          },
        },
        selectedGroup: {
          read() {
            return selectedGroupVar();
          },
        },
        selectedCompany: {
          read() {
            return selectedCompanyVar();
          },
        },
        selectedBranch: {
          read() {
            return selectedBranchVar();
          },
        },
        selectedRole: {
          read() {
            return selectedRoleVar();
          },
        },
        ClientApproval: {
          read() {
            return ClientApproval();
          },
        },
      },
    },
  },
});

export default cache;
