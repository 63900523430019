import React, { useState, useMemo, useCallback, useEffect } from "react";

// create context
interface ISidebarContext {
  isSidebarOpen: boolean;
  closeSidebar: () => void;
  toggleSidebar: () => void;
}

export const SidebarContext = React.createContext<ISidebarContext>({
  isSidebarOpen: false,
  closeSidebar: () => {},
  toggleSidebar: () => {},
});

interface ISidebarPovider {
  children: React.ReactNode;
}

export const SidebarProvider = ({ children }: ISidebarPovider) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [setIsSidebarOpen, isSidebarOpen]);

  const closeSidebar = useCallback(() => {
    setIsSidebarOpen(false);
  }, [setIsSidebarOpen]);

  useEffect(() => {}, [isSidebarOpen]);
  const value = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
    }),
    [isSidebarOpen, toggleSidebar, closeSidebar]
  );

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
