import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import CustomToastContainer from '../components/CustomToastContainer';
import TextInput from '../components/InputComponents/TextInput';
import { useSetPasswordMutation, useValidateConfirmTokenMutation } from '../generated/graphql-generated';


const choosePasswordSchema = yup.object().shape({
    Password: yup.string().required("This field is mandatory"),
    ConfirmPassword: yup.string()
        .oneOf([yup.ref('Password'), null], 'Passwords must match')

});


function useQueryString() {
    return new URLSearchParams(useLocation().search);
}

function SetPassword() {
    let query = useQueryString();
    let history = useHistory();
    const failed = (message?: string) => toast.error(`Error : ${message!}`);
    const [validateConfirmToken] = useValidateConfirmTokenMutation();
    const [confirmEmailMutation] = useSetPasswordMutation();
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(choosePasswordSchema),
    });
    async function validateToken() {
        try {
            const data = await validateConfirmToken({ variables: { token: (query.get("token")!) } });
            if (!data.data?.validateConfirmToken.isValid) {
                failed(data.data?.validateConfirmToken.message)
                history.push("/login");
            } 
        } catch (error: any) {
            failed(error);
        }
    }
    useEffect(() => { validateToken() }, [])
    const onSubmit = async (formData: any) => {
        try {
            console.log(formData);

            const data = await confirmEmailMutation({ variables: { password: formData['Password'], token: (query.get("token")!) } });
            if (data.data?.setPassword.success) {
                toast.success(data.data?.setPassword.message)
                history.push("/login");
            } else {
                failed(data.data?.setPassword.message);
            }
        } catch (error: any) {
            failed(error);
        }
    };
    return (
        <>
            <CustomToastContainer />
            <div className="grid place-items-center h-screen">
                <div className="w-full max-w-xs">
                    <p className='font-semibold text-2xl text-center'>Set password</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="px-0 pt-6 pb-8 mb-4">
                        <div className="mb-2">
                            <TextInput
                                isMandatory={false}
                                name="Password"
                                type="password"
                                control={control}
                                errors={errors}
                                label="Password"
                            />

                        </div>
                        <div className="mb-4">
                            <TextInput
                                isMandatory={false}
                                name="ConfirmPassword"
                                type="password"
                                control={control}
                                errors={errors}
                                label="Confirm Password"
                            />
                        </div>
                        <button className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Save
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SetPassword
