import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@windmill/react-ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { default as ImageDark, default as ImageLight } from '../assets/img/forgot_password.png';
import CustomToastContainer from '../components/CustomToastContainer';
import TextInput from '../components/InputComponents/TextInput';
import { useForgotPasswordMutation } from '../generated/graphql-generated';

function ForgotPassword() {
  const [forgotPasswordMutation] = useForgotPasswordMutation()
  let history = useHistory();
  const failed = (message?: string) => toast.error(`Error : ${message!}`);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object().shape({
      Email: yup.string().email().required("This field is mandatory")
    })),
  });
  const onSubmit = async (formData: any) => {
    try {
      const data = await forgotPasswordMutation({ variables: { email: formData['Email'] } });
      if (data.data?.forgotPassword.success) {
        toast.success(data.data?.forgotPassword.message)
        history.push("/login");
      } else {
        failed(data.data?.forgotPassword.message);
      }
    } catch (error: any) {
      failed(error);
    }
  };
  return (<>
    <CustomToastContainer />
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full dark:hidden"
              src={ImageLight}
              alt="Office"
            />
            <img
              aria-hidden="true"
              className="hidden object-cover w-full h-full dark:block"
              src={ImageDark}
              alt="Office"
            />
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-full">
                <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                  Forgot password ?
                </h1>

                <TextInput

                  isMandatory={false}
                  name="Email"
                  type="email"
                  control={control}
                  errors={errors}
                  label="Enter email"
                />
                <Button tag={"button"} block className="mt-4" type='submit'>
                  Recover password
                </Button>
              </div>
            </form>
          </main>
        </div>
      </div>
    </div>
  </>
  )
}

export default ForgotPassword

