import React from "react";
import { lazy } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import ProtectedRoute from "./components/ProtectedRoutes";
import setPassword from "./pages/set-password";
import ForgotPassword from "./pages/ForgotPassword";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const isAuth = localStorage.getItem("isAuthenticated");

function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/set-password" component={setPassword} />
          {/* Place new routes over this */}
          <ProtectedRoute path="/app" isAuthenticated={isAuth === "true"}>
            <Route path="/app" component={Layout} />
          </ProtectedRoute>
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
