import gql from "graphql-tag";

export const typeDefs = gql`
  extend type Query {
    loggedInUserDetails: User!
    selectedGroup: Int
    selectedCompany: Int
    selectedBranch: Int
  }
`;
