import { Label, Input, HelperText } from "@windmill/react-ui/";
import React from "react";
import { Control, Controller } from "react-hook-form";
import get from "lodash-es/get";
interface TextInputProps {
  control: Control<any, object>;
  defaultValue?: string | number;
  name: string;
  label?: string;
  disabled?: boolean;
  errors: { [key: string]: any };
  type?: string;
  isMandatory?: boolean;
}

function TextInput({
  control,
  errors,
  label,
  defaultValue,
  disabled,
  name,
  isMandatory = true,
  type = "text",
  ...props
}: TextInputProps) {
  return (
    <Controller
      {...props}
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field }) => (
        <Label>
          <span>{label}</span>
          <span className="text-red-500 text-sm">{isMandatory === true ? "*" : ""}</span>
          <Input
            disabled={disabled}
            {...field}
            css=""
            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full h-10 sm:text-sm border-gray-300 "
            type={type}
          />
          <HelperText className="text-red-500">{get(errors, name)?.message}</HelperText>
        </Label>
      )}
    />
  );
}

export default TextInput;
